import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/baseLayout"
import SEO from "../components/seo"
import { kebabCase } from "lodash"
const TextPost = ({ data: { prismicBasicPos }}) => {
    const { data } = prismicBasicPos
    
    const date = data.release_date
    const title = data.title.text
    const category = data.category.document[0].data.cat_name.text
    const formattedCat = kebabCase(category)
    const body = data.body.map(bodyType=>{
      if (bodyType.__typename === "PrismicBasicPosBodyText"){
        return bodyType["primary"]["text"]["html"]
      }
      else if (bodyType.__typename === "PrismicBasicPosBodyQuote"){
        let quoteHtml = bodyType["primary"]["quote"]["text"]
        if (!quoteHtml.replace(" ", "")){
          return "<span></span>"
        }
        quoteHtml = bodyType["primary"]["quote"]["html"]
        console.log("not returned....")
        return `<blockquote>${quoteHtml}</blockquote>`
      }
      else if (bodyType.__typename === "PrismicBasicPosBodyCodeSnippet"){

        let codeContent = bodyType["primary"]["code_snippet"]["text"]
        if (!codeContent.replace(" ", "")){
          return ""
        }
        else{
          return bodyType["primary"]["code_snippet"]["html"]
        }
      }
    })
    return (
  <Layout>
    <SEO title={ title } />
        <section id="primary" className="container main_content_area">
        <h4 className="page-title screen-reader-text">{ title }</h4>
        {/* start content */}
        
        <div className="row full_width_post_single no_sidebar_post_single"><div className="col12">
        <article className="blog_post_container customhentry post type-post format-standard has-post-thumbnail hentry">
        <div className="single_post_body no_post_banner">
            <div className="post_header post_header_single">
                <h1 className="entry-title title post_title">{ title }</h1>
            </div>
        
            <div className="post_meta_container post_meta_container_single clearfix">
                <div className="post_meta_item meta_item_author">
                    Goh Yong Hua
                </div>
                <div className="post_meta_item meta_item_category">
    <Link to={`articles/categories/${formattedCat}`}>{ category }</Link>
                  
                </div>
                <div className="post_meta_item meta_item_date">
                    { date }
                </div>
            </div>
        </div>
        
        <div className="post_body no_post_banner">
            <div className="post_info_wrapper">
                <div className="entry-content blog_post_text blog_post_description clearfix">
                    {body.map((content, idx)=>(
                      <div className="slice" key={idx} dangerouslySetInnerHTML={{__html:content}}>
                      </div>
                    ))}
                </div>
            </div> {/* end post info wrapper */}
        </div>
        </article>
        </div></div> {/* end content */}
        
        </section>
  </Layout>
)
}
export default TextPost

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicBasicPos(uid: { eq: $uid }) {
      uid
      data{
        category{
          document{
            data{
              cat_name{
                text
              }
            }
          }
        }
        title{
          text
        }
        release_date
        body{
          __typename
          ... on PrismicBasicPosBodyText{
            primary{
              text{
                html
              }
            }
        }
        ... on PrismicBasicPosBodyQuote{
          primary{
            quote{
              html
              text
            }
          }
        }
      ... on PrismicBasicPosBodyCodeSnippet{
        primary{
          code_snippet{
            html
            text
          }
        }
      }
        }
      }
    }
  }
`